<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', mediaAddForm)">
        <b-card-code>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-group
                  label="Select Languages"
                  label-for="mc-select-language"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="mediaAddForm.selectedLanguages"
                    class="language"
                    :disabled="true"
                    placeholder="Select Languages"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="mediaAddForm.selectedLanguages"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>

        <app-collapse
          v-for="(item, index) in mediaAddForm.mediaLanguage"
          :id="item.id"
          :key="index"
          accordion
          :type="collapseType"
        >
          <app-collapse-item
            :is-visible="isVisible"
            :title="`${item.language ? item.language : item.languageName}`"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.language ? item.language : item.languageName} title`"
                  rules="required|max:60|min:3"
                >
                  <b-form-group
                    label="Banner Title"
                    label-for="mc-banner-title"
                  >
                    <b-form-input
                      v-model="mediaAddForm.mediaLanguage[index].title"
                      class="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter banner title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.language ? item.language : item.languageName} sub-title`"
                  rules="required|max:60|min:3"
                >
                  <b-form-group label="Sub title" label-for="mc-sub-title">
                    <b-form-input
                      v-model="mediaAddForm.mediaLanguage[index].sub_title"
                      class="sub_title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter banner sub-title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <validation-provider
                  v-if="!mediaAddForm.url"
                  #default="{ errors }"
                  :name="`${item.language ? item.language : item.languageName} description`"
                  rules="required"
                >
                  <b-form-group
                    v-if="!mediaAddForm.url"
                    label="Description"
                    label-for="mc-description"
                  >
                    <template>
                      <quill-editor
                        v-model="mediaAddForm.mediaLanguage[index].description"
                        :options="snowOption"
                      />
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>

        <b-card-code class="mt-2">
          <!-- <span class="ml-1 mb-1"><h5>SEO Meta:</h5></span> -->
          <b-row>
            <b-col>
              <h4>SEO Meta:</h4>
            </b-col>
          </b-row>
          <b-row
            class="mb-1 mt-1 mr-1"
            v-for="(item, index) in mediaAddForm.seo_meta"
            :id="item.id"
            :key="index"  
            ref="seoMetaRow"
          >       
          <b-col
              md="12"
              v-if="
                mediaAddForm.seo_meta[index].key != 'robots' &&
                mediaAddForm.seo_meta[index].key != 'type'
              "
            >
              <validation-provider
                #default="{ errors }"
                :name="upperCaseFunc(mediaAddForm.seo_meta[index].key)"
                :rules="
                  mediaAddForm.seo_meta[index].key == 'title'
                    ? 'required|max:60|min:1'
                    : mediaAddForm.seo_meta[index].key == 'description'
                    ? 'required|max:160|min:1'
                    : mediaAddForm.seo_meta[index].key == 'canonical' 
                    ? 'max:60|min:3|noWhiteSpace'
                    : 'required|min:1'
                "
              >
                <b-form-group
                  :label="upperCaseFunc(mediaAddForm.seo_meta[index].key)"
                  label-for="mc-value"
                >
                  <template>
                    <div v-if="mediaAddForm.seo_meta[index].key !== 'canonical'">
                      <b-form-textarea
                        v-model="mediaAddForm.seo_meta[index].value"
                        id="textarea-default"
                        rows="2"
                      />
                    </div>
                    <b-input-group
                      v-if="mediaAddForm.seo_meta[index].key == 'canonical'"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :prepend="frontendPath"
                    >
                      <b-form-input
                        v-model="mediaAddForm.slug"
                        id="textarea-default"
                        class="form-control-merge"
                        @input="mediaAddForm.seo_meta[index].value = `${frontendPath}${mediaAddForm.slug}`"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              v-if="mediaAddForm.seo_meta[index].key == 'robots'"
            >
              <label for="Robots"><strong>Robots</strong></label>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Index"
                    rules="required"
                  >
                    <b-form-group
                      label="Index"
                      label-for="mc-index"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="mediaAddForm.seo_meta[index].value.index"
                        placeholder="Select Index"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotIndexTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Follow"
                    rules="required"
                  >
                    <b-form-group
                      label="Follow"
                      label-for="mc-follow"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="mediaAddForm.seo_meta[index].value.follow"
                        placeholder="Select Follow"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotFollowTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="mediaAddForm.seo_meta[index].key == 'type'">
              <validation-provider
                #default="{ errors }"
                name="Open Graph Type"
                rules="required"
              >
                <b-form-group
                  label="Open Graph Type"
                  label-for="mc-og-type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="mediaAddForm.seo_meta[index].value"
                    placeholder="Select Open Graph Type"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OGTypes"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>

        <b-card-code class="mt-2">
          <b-row class="mb-2">
            <span class="ml-1 mb-0"><h5>Card Size: {{mediaAddForm.type}}</h5></span>
            <b-col md="12" class="demo-inline-spacing">
              <div
                class="card-sample-media mt-0"
                :class="{'is-checked': mediaAddForm.type == '1'}"
                @click="selectSizeType('1')"
                style="width: 53%;">
                <h1>1</h1></div>
              <div
                class="card-sample-media mt-0"
                :class="{'is-checked': mediaAddForm.type == '1/2'}"
                @click="selectSizeType('1/2')"
                style="width: 25%;">
                <h1>1/2</h1></div>
              <div
                class="card-sample-media mt-0"
                :class="{'is-checked': mediaAddForm.type == '1/4'}"
                @click="selectSizeType('1/4')"
                style="width: 15%;">
                <h1>1/4</h1></div>
            </b-col>
          </b-row>
          <b-row col>
            <b-col md="12">
              <b-form-checkbox
                v-model="mediaAddForm.url"
                name="check-button"
                switch
                inline
                class="mt-2"
              >
                URL
              </b-form-checkbox>
              <b-form-checkbox
                v-model="uploadVideo"
                name="check-button"
                switch
                inline
                class="mt-2"
              >
                Upload Video
              </b-form-checkbox>
              <div class="d-block">
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                />
              </div>
              <validation-provider
                v-if="!mediaAddForm.url"
                #default="{ errors }"
                name="URL Slug"
                rules="required|max:60|min:3|noWhiteSpace"
              >
                <b-form-group
                  label="URL Slug"
                  label-for="mc-banner-slug"
                  class="mt-2"
                >
                  <b-form-input
                    v-model="mediaAddForm.slug"
                    class="mc-banner-slug"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter url slug"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="mediaAddForm.url"
                #default="{ errors }"
                name="Link"
                rules="required|max:60|min:3|url"
              >
                <b-form-group
                  v-if="mediaAddForm.url"
                  label="Link"
                  label-for="mc-banner-title"
                  class="mt-2"
                >
                  <b-form-input
                    v-model="mediaAddForm.link"
                    class="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter link address"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-row v-if="mediaAddForm.url">
                <b-col md="12">
                  <b-alert variant="primary" show>
                    <div class="alert-body">
                      <span>Valid URL should contain http or https.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" class="mt-2">
              <div class="helper" />
              <div
                class="drop display-inline align-center"
                @dragover.prevent
                @drop="onDrop"
              >
                <div class="helper" />
                <label
                  v-if="!imagePreview && !mediaAddForm.banner_media"
                  class="btn btn-primary display-inline"
                >
                  SELECT OR DROP AN IMAGE
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    @change="onChange"
                  />
                </label>
                <div
                  v-if="imagePreview"
                  class="display-inline align-center"
                  :class="{ image: true }"
                >
                  <img :src="imagePreview" alt="Image" class="img my-2" />
                  <button class="btn btn-primary mb-2" @click="removeFile">
                    REMOVE
                  </button>
                </div>
                <div
                  v-if="mediaAddForm.banner_media && !imagePreview"
                  class="display-inline align-center"
                  :class="{ image: true }"
                >
                  <img
                    :src="mediaAddForm.banner_media"
                    alt="Image"
                    class="img mt-2"
                  />
                  <br />
                  <br />
                  <button class="btn btn-primary mb-2" @click="removeFile">
                    REMOVE
                  </button>
                </div>
              </div>
            </b-col>

            <b-col md="6">
              <div class="helper" />
              <div v-if="uploadVideo" class="drop align-center mt-2">
                <div class="helper" />
                <label v-if="!mediaAddForm.video" class="btn btn-primary">
                  SELECT OR DROP Video
                  <input type="file" @change="selectedVideo" />
                </label>
                <div v-show="mediaAddForm.video" class="video">
                  <video id="video" :src="src" class="" controls>
                    <source id="source" type="video/mp4" />
                  </video>
                  <button class="btn btn-primary mb-2" @click="removeVideo">
                    REMOVE
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-alert class="mt-2" variant="primary" show>
                <div class="alert-body">
                  <span>Allowed JPG, GIF or PNG. Max size of 1MB.</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-col md="12" class="demo-inline-spacing mt-2">
            <b-button :disabled="loading" variant="primary" type="submit">
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              :to="{
                name:
                  checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                    ? 'magazine-media'
                    : 'dashboard',
              }"
            >
              Cancel
            </b-button>

            <!-- <b-button v-if="!checkAccess.view"
                      variant="secondary"
                      :to="{name: 'dashboard'}"
            >
              Cancel
            </b-button> -->
          </b-col>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BAlert,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend
} from "bootstrap-vue";
import languageService from "@/services/language/language.service";
import { RoleEnum, layoutOptions, resConditionCheck, RobotIndexTypes,
      RobotFollowTypes,
      OGTypes, } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import mediaService from "@/services/banner-management/media/media.service";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required } from "@core/utils/validations/validations";

export default {
  components: {
    BCardCode,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    quillEditor,
    BFormCheckbox,
    BButton,
    BAlert,
    BFormTextarea,
  },

  props: {
    mediaDetails: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    frontendPath() {
      return `https://${this.$store.state.auth.configData.frontendDomain}/`;
    }
  },

  data() {
    return {
      mediaAddForm: {
        selectedLanguages: this.mediaDetails.selectedLanguages || [],
        banner_media: this.mediaDetails.banner_media || "",
        link: this.mediaDetails.link || "",
        url: this.mediaDetails.url || false,
        slug: this.mediaDetails.slug || "",
        type: this.mediaDetails.type || "",
        video: this.mediaDetails.video || "",
        seo_meta: this.mediaDetails.seo_meta && this.mediaDetails.seo_meta.length ? this.mediaDetails.seo_meta : [
          { key: "title", value: "" },
          { key: "description", value: "" },
          { key: "canonical", value: "" },
          {
            key: "robots",
            value: {
              index: "index",
              follow: "follow",
            },
          },
          { key: "type", value: "website" },
        ],
        mediaLanguage: [],
      },
      collapseType: "margin",
      checkLoginRole,
      imagePreview: "",
      snowOption: {},

      required,
      layoutOptions,
      loading: false,
      RoleEnum,
      staticObject: {
        title: "",
        sub_title: "",
        description: "",
        language: "",
        language_id: "",
        id: "",
      },
      uploadVideo: this.mediaDetails.uploadVideo || false,
      src: this.mediaDetails.src || "",
      isVisible: true,
      RobotIndexTypes,
      RobotFollowTypes,
      OGTypes,
    };
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    await this.getLanguageDropdown();
  },

  watch: {
    "mediaAddForm.slug": {
      handler() {
        this.mediaAddForm.seo_meta.forEach(m=> {
          if(m.key=='canonical') {
            m.value = `https://${this.$store.state.auth.configData.frontendDomain}/offers/${newForm.slug}`
          }
        })
      }
    }
  },

  methods: {
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.mediaAddForm.selectedLanguages = res.data.data.map((e) => ({
            label: e.name,
            id: e.id,
          }));

          if (this.$route.name === "media-add") {
            this.mediaAddForm.mediaLanguage = [];
            for (let i = 0; i < res.data.data.length; i += 1) {
              this.mediaAddForm.mediaLanguage.push({
                ...this.staticObject,
                language_id: res.data.data[i].id,
                languageName: res.data.data[i].name
              });
            }
            console.log("this.mediaDetails.mediaLanguage", this.mediaAddForm.mediaLanguage)
          } else if(this.$route.name === "media-edit") {
            for (
              let i = 0;
              i < this.mediaAddForm.selectedLanguages.length;
              i += 1
            ) {
              this.mediaAddForm.mediaLanguage.push({
                ...this.staticObject,
                language: this.mediaAddForm.selectedLanguages[i].label,
                language_id: this.mediaAddForm.selectedLanguages[i].id,
              });
              this.mediaAddForm.mediaLanguage[i].title =
                this.mediaDetails.mediaLanguage[i] &&
                this.mediaDetails.mediaLanguage[i].title
                  ? this.mediaDetails.mediaLanguage[i].title
                  : "";
              this.mediaAddForm.mediaLanguage[i].sub_title =
                this.mediaDetails.mediaLanguage[i] &&
                this.mediaDetails.mediaLanguage[i].subTitle
                  ? this.mediaDetails.mediaLanguage[i].subTitle
                  : "";
              this.mediaAddForm.mediaLanguage[i].description =
                this.mediaDetails.mediaLanguage[i] &&
                this.mediaDetails.mediaLanguage[i].description
                  ? this.mediaDetails.mediaLanguage[i].description
                  : "";
              this.mediaAddForm.mediaLanguage[i].id =
                this.mediaDetails.mediaLanguage[i] &&
                this.mediaDetails.mediaLanguage[i].id
                  ? this.mediaDetails.mediaLanguage[i].id
                  : "";
            }
          }
        }
      } catch (error) {
        console.log("MEdia addd eeer",error)
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    selectSizeType(type) {
      this.mediaAddForm.type = type;
    },

    async validateForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          this.$refs.simpleRules.validate();
          valid = false;
        }
      });
      return valid;
    },

    async selectedVideo(event) {
      const { files } = event.target;
      if (files[0].type === "video/mp4" && files[0].size <= 2020775) {
        // eslint-disable-next-line prefer-destructuring
        this.mediaAddForm.video = files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          this.src = e.target.result;
          const video = document.getElementById("video");
          const source = document.getElementById("source");
          source.setAttribute("src", this.src);
          video.load();
          video.play();
        };

        reader.readAsDataURL(files[0]);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Mp4 format allowed with max size of 2 MB",
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    removeVideo() {
      this.mediaAddForm.video = "";
    },

    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/gif" ||
          file.type === "video/mp4") &&
        file.size <= 1048576
      ) {
        this.mediaAddForm.banner_media = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.mediaAddForm.banner_media = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    removeFile() {
      this.mediaAddForm.banner_media = "";
      this.imagePreview = "";
    },

    /* repeateAgain() {
      this.mediaAddForm.seo_meta.push({
        "key": "",
        "value": "",
        "type": ""
      })

      this.$nextTick(() => {
        // this.trAddHeight(this.$refs.seoMetaRow[0].offsetHeight)
      })
    }, */

    /* removeItem(index) {
      if(this.mediaAddForm.seo_meta.length <= 1) {
        this.mediaAddForm.seo_meta[0] = "";
      } else {
        this.mediaAddForm.seo_meta.splice(index, 1)
        // this.trTrimHeight(this.$refs.emailRow[0].offsetHeight)
      }
    }, */
    upperCaseFunc(data) {
      const title = data.replace("_", " ");
      return title.charAt(0).toUpperCase() + title.slice(1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}

.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

/* .helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
} */

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img,
#video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  width: auto;
  max-height: 250px;
  max-width: 400px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-sample-media {
  height: 150px;
  color: #fff;
  background: #f6f6f6;
  border: 1px dashed #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-sample-media:hover {
  box-shadow: 0px 0px 10px 1px #9e9e9e;
  background: #fff;
  cursor: pointer;
  border: none;
}

.is-checked {
  box-shadow: 0px 0px 10px 1px #9e9e9e;
  background: #fff;
  cursor: pointer;
  border: none;
}

.seoMetaRemove{
  cursor: pointer;
  border-radius: 50%;
  transition: .25s ease-in-out;
}
.seoMetaRemove:hover {
  background: rgb(187, 14, 14);
  color: #fff;
  box-shadow: 0px 0px 10px 2px #efefef;
}
.input-group-text {
  background: #f2f2f2;
  padding-right: 1px;
  font-weight: bold;
  cursor: default;
}
</style>
