<template>
  <div>
    <MediaAdd
      v-if="!id && !isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :media-details="mediaDetails"
      @on-Submit="createMedia"
    />
    <MediaAdd
      v-if="id && isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :media-details="mediaDetails"
      @on-Submit="editMedia"
    />
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BAlert,
} from "bootstrap-vue";
import {
  RoleEnum,
  resConditionCheck,
  layoutOptions,
  ImageError,
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import mediaService from "@/services/banner-management/media/media.service";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import MediaAdd from "@/views/banner-management/magazine-media/media-add/MediaAdd.vue";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required } from "@core/utils/validations/validations";

export default {
  components: {
    BCardCode,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    quillEditor,
    BFormCheckbox,
    BButton,
    BAlert,
    MediaAdd,
  },

  data() {
    return {
      mediaDetails: {},
      collapseType: "margin",
      checkLoginRole,
      imagePreview: "",
      snowOption: {},

      required,
      layoutOptions,
      loading: false,
      RoleEnum,
      staticObject: {
        title: "",
        sub_title: "",
        description: "",
        language: "",
        language_id: "",
        id: "",
      },
      uploadVideo: false,
      src: "",
      isVisible: true,
      isLoaded: false,
      id: this.$route.params.id ? this.$route.params.id : "",
    };
  },

  watch: {
    async $route() {
      if (this.$route.name === "media-add") {
        this.id = "";
        this.mediaDetails = {};
        this.isLoaded = false;
      } else if (this.$route.name === "media-edit") {
        this.id = this.$route.params.id;
        this.isLoaded = true;
      }
    },
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (this.$route.name === "media-edit") {
      await this.getMediaById(this.$route.params.id);
    }
  },

  methods: {
    async getMediaById(id) {
      try {
        const res = await mediaService.getMediaById(id);
        if (resConditionCheck(res.status) && res.data.data) {
          this.isLoaded = true;
          this.mediaDetails.url = res.data.data.url;
          this.mediaDetails.link = res.data.data.link;
          this.mediaDetails.mediaLanguage = res.data.data.mediaLanguages;
          this.mediaDetails.banner_media = res.data.data.bannerMedia;
          this.mediaDetails.video = this.mediaDetails.src = res.data.data.video;
          this.mediaDetails.type = res.data.data.type;
          this.mediaDetails.slug = res.data.data.slug;
          this.mediaDetails.uploadVideo = res.data.data.isVideo;
          this.mediaDetails.seo_meta = res.data.data.seoMeta;
        }
      } catch (error) {
        console.error("ALERT ERROR", error);
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async createMedia(mediaAddForm) {
      const valid = await this.$refs.simpleRules.validateForm();

      if (!mediaAddForm.banner_media) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: ImageError.Banner_Not_Found,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && mediaAddForm.banner_media) {
        this.loading = true;
        try {
          const formdata = new FormData();
          formdata.append("banner_media", mediaAddForm.banner_media);
          formdata.append("link", mediaAddForm.link);
          formdata.append("url", mediaAddForm.url);
          formdata.append("type", mediaAddForm.type || "1/4");
          formdata.append("slug", mediaAddForm.slug);
          formdata.append(
            "mediaLanguage",
            JSON.stringify(mediaAddForm.mediaLanguage)
          );
          formdata.append("video", mediaAddForm.video);
          formdata.append(
            "seo_meta",
            JSON.stringify(mediaAddForm.seo_meta)
          );
          const res = await mediaService.addMedia(formdata);
          this.loading = false;
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push("/media/list");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    async editMedia(mediaAddForm) {
      const valid = await this.$refs.simpleRules.validateForm();

      if (!mediaAddForm.banner_media) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: ImageError.Banner_Not_Found,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && mediaAddForm.banner_media) {
        this.loading = true;
        try {
          const formdata = new FormData();
          formdata.append("banner_media", mediaAddForm.banner_media);
          formdata.append("link", mediaAddForm.link);
          formdata.append("url", mediaAddForm.url);
          formdata.append("type", mediaAddForm.type);
          formdata.append("slug", mediaAddForm.slug);
          formdata.append(
            "mediaLanguage",
            JSON.stringify(mediaAddForm.mediaLanguage)
          );
          formdata.append("video", mediaAddForm.video);
          formdata.append(
            "seo_meta",
            JSON.stringify(mediaAddForm.seo_meta)
          );
          const res = await mediaService.editMedia(
            this.$route.params.id,
            formdata
          );
          this.loading = false;
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push("/media/list");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}

.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

/* .helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
} */

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img,
#video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  width: auto;
  max-height: 250px;
  max-width: 400px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
